<template>
  <div class="content">
    <!-- 头部 -->
    <div class="header">
      <div class="left">
        <img src="@/assets/logo.png" style="width: 100px; height: 100px" />
        <div class="left_1">
          <p style="font-size: 34px">逛集网</p>
          <p style="color: #c1c1c1; font-size: 18px">guangji.wang</p>
        </div>
      </div>
      <div class="center">
        <p>Mission</p>
        <p>Our product</p>
        <p>Our territory</p>
        <p>Our producers</p>
        <p>Certication</p>
        <p>Contact us</p>
      </div>
      <div class="right">
        <p style="font-size: 14px; font-weight: 600; margin-left: 10px">LINK TO SOCIAL NETWORKS</p>
        <div style="text-align: center">
          <p v-for="item in 5" :key="item"></p>
        </div>
      </div>
    </div>
    <!-- 头部下面第一块内容 大视频播放-->
    <div class="header_1">
      <video-player
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        :options="playerOptions"
      ></video-player>
    </div>
    <!-- 大视频播放下面那个 -->
    <div class="header_2">
      <div class="top">Mission of Guanji</div>
      <div class="text">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
        galley of type and scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
        passages, and more recently with desktop publishing software like Aldus PageMaker including
        versions of Lorem Ipsum.
      </div>
      <div class="img_">
        <div class="img_text" v-for="item in 4" :key="item">
          <img
            src="@/assets/aa.jpg"
            :style="{
              width: imgWidth / 7.9 + 'px',
              height: imgWidth / 7.9 + 'px',
              borderRadius: '100%',
            }"
          />
          <p>Crowdfunding for left-behind children</p>
        </div>
      </div>
    </div>
    <div style="height: 50px"></div>
    <!-- 头部下面第三块内容 -->
    <div class="header_3">
      <div class="top">Discover the Guanji products</div>
      <button class="btn">GO TO PURCHASE</button>
    </div>
    <!-- 中间 轮播图 带buy now按钮的-->
    <div class="center header_2">
      <div class="top">Our products</div>
      <div class="text">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
        galley of type and scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
        passages, and more recently with desktop publishing software like Aldus PageMaker including
        versions of Lorem Ipsum.
      </div>
      <div style="position: relative; padding-bottom: 30px">
        <swiper :options="swiperOption">
          <swiper-slide v-for="(item, index) in 6" :key="index">
            <img class="swiper-slide" src="@/assets/aa.jpg" />
            <div class="swiper-slide" style="text-align: left">
              <div style="font-size: 26px; font-weight: bold; margin: 20px 0">
                Product {{ item }}
              </div>
              <div style="font-weight: bold">
                Lorem Ipsum is simply dummy text of the printing and typesetting in-dustry.
              </div>
              <button class="btn" style="">BUY NOW</button>
            </div>
          </swiper-slide>
          <!-- 前进后退按钮 -->
          <div
            class="swiper-button-next"
            slot="button-next"
            :style="{
              top: imgWidth / 7.9 / 2 + 'px',
            }"
          ></div>
          <div
            class="swiper-button-prev"
            slot="button-prev"
            :style="{
              top: imgWidth / 7.9 / 2 + 'px',
            }"
          ></div>
        </swiper>
      </div>
    </div>
    <!-- 中间下面第一块 -->
    <div class="header_3">
      <div class="top">Show all guanji products</div>
      <button class="btn">GO TO PURCHASE</button>
    </div>
    <!-- 4个视频 播放器-->
    <div class="videos-4">
      <div class="title">Our territory, our tradition</div>
      <!-- 上面2个视频播放器 -->
      <div style="display: flex; justify-content: center; padding-bottom: 70px">
        <div style="text-align: left">
          <video-player
            ref="videoPlayer1"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
          <div class="title" style="padding: 0">Title of the video</div>
        </div>
        <div style="text-align: left">
          <video-player
            ref="videoPlayer1"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
          <div class="title" style="padding: 0">Title of the video</div>
        </div>
      </div>
      <!-- 下面2个播放器 -->
      <div style="display: flex; justify-content: center; padding-bottom: 70px">
        <div style="text-align: left">
          <video-player
            ref="videoPlayer1"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
          <div class="title" style="padding: 0">Title of the video</div>
        </div>
        <div style="text-align: left">
          <video-player
            ref="videoPlayer1"
            :playsinline="true"
            :options="playerOptions"
          ></video-player>
          <div class="title" style="padding: 0">Title of the video</div>
        </div>
      </div>
    </div>
    <!-- 纯正轮播图不带buy now按钮的 -->
    <div class="swiper_2 center">
      <div class="title">Our producers</div>
      <div style="position: relative" :style="{ paddingBottom: imgWidth >= '1300' ? '30px' : 0 }">
        <swiper :options="swiperOption" style="margin-top: 0">
          <swiper-slide v-for="(item, index) in 6" :key="index">
            <img
              :style="{
                width: imgWidth / 7.9 + 'px',
                height: imgWidth / 7.9 + 'px',
                borderRadius: '100%',
              }"
              src="@/assets/aa.jpg"
            />
            <div style="text-align: center" class="swiper-slide">
              <div style="font-size: 26px; font-weight: bold; margin: 20px 0">
                Product {{ item }}
              </div>
            </div>
          </swiper-slide>
          <!-- 前进后退按钮 -->
          <div
            class="swiper-button-next"
            slot="button-next"
            :style="{
              top: imgWidth / 7.9 / 2 + 'px',
            }"
          ></div>
          <div
            class="swiper-button-prev"
            slot="button-prev"
            :style="{
              top: imgWidth / 7.9 / 2 + 'px',
            }"
          ></div>
        </swiper>
      </div>
    </div>

    <div class="header_2" style="margin-bottom: 0">
      <div class="top">Certication</div>
      <div class="text">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
        galley of type and scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
        It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum
        passages, and more recently with desktop publishing software like Aldus PageMaker including
        versions of Lorem Ipsum.
      </div>
      <div class="img_">
        <div class="img_text" v-for="item in 3" :key="item">
          <img
            src="@/assets/aa.jpg"
            :style="{
              width: imgWidth / 7.9 + 'px',
              height: imgWidth / 7.9 + 'px',
            }"
            style="border-radius: 0"
          />
          <p>Certiicate {{ item }}</p>
        </div>
      </div>
    </div>

    <div class="header_3">
      <div class="top">Show all guanji products</div>
      <button class="btn">GO TO PURCHASE</button>
    </div>
    <!-- 底部输入框那块 -->
    <div class="suggest">
      <div class="title">Contact us</div>
      <div style="margin: 0px 23%; margin-bottom: 30px; font-weight: bold">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
        galley
      </div>
      <el-form label-position="top" label-width="80px" :model="form" style="margin: 0px 30%">
        <el-form-item label="NAME" style="text-align: left">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="E-MAIL" style="text-align: left">
          <el-input v-model="form.mail"></el-input>
        </el-form-item>
        <el-form-item label="MESSAGE" style="text-align: left">
          <el-input
            type="textarea"
            :autosize="{ minRows: 6, maxRows: 9 }"
            v-model="form.message"
          ></el-input>
        </el-form-item>
        <div style="text-align: left">
          <button class="btn">SEND MESSAGE</button>
        </div>
      </el-form>
    </div>
    <!-- 底部 -->
    <div class="bottom">
      <div class="left">
        <img src="@/assets/guangji.png" />
        <div class="text">
          <p>CORPORATE DATA</p>
          <p>地址：北京市朝阳区CBD 商务区</p>
          <p>电话：15822980377</p>
          <p>邮箱：1175053853@qq.com</p>
        </div>
      </div>
      <div class="right">
        <p>Link 1</p>
        <p>Link 2</p>
        <p>Link 3</p>
        <p>Link 4</p>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      video1: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",
      form: {
        name: "",
        mail: "",
        message: "",
      },
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      windowHeight: document.documentElement.clientHeight, //实时屏幕高度
      imgWidth: "",
    };
  },
  // <!--在watch中监听实时宽高-->
  watch: {
    windowHeight(val) {
      let that = this;
      console.log("实时屏幕高度：", val, that.windowHeight);
    },
    windowWidth(val) {
      let that = this;
      this.imgWidth = val;
      console.log("实时屏幕宽度：", this.imgWidth);
    },
  },
  mounted() {
    this.imgWidth = document.documentElement.clientWidth;
    var that = this;
    window.onresize = () => {
      return (() => {
        window.fullHeight = document.documentElement.clientHeight;
        window.fullWidth = document.documentElement.clientWidth;
        that.windowHeight = window.fullHeight; // 高
        that.windowWidth = window.fullWidth; // 宽
      })();
    };
  },
  computed: {
    playerOptions() {
      const playerOptions = {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        // aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: require("../assets/one.mp4"), //url地址
          },
        ],
        poster:
          "https://image.baidu.com/search/detail?ct=503316480&z=0&ipn=d&word=%E5%9B%BE%E7%89%87&step_word=&hs=0&pn=74&spn=0&di=7189064908862914561&pi=0&rn=1&tn=baiduimagedetail&is=0%2C0&istype=0&ie=utf-8&oe=utf-8&in=&cl=2&lm=-1&st=undefined&cs=379519332%2C1964113923&os=37892519%2C306225822&simid=379519332%2C1964113923&adpicid=0&lpn=0&ln=952&fr=&fmq=1678862560850_R&fm=&ic=undefined&s=undefined&hd=undefined&latest=undefined&copyright=undefined&se=&sme=&tab=0&width=undefined&height=undefined&face=undefined&ist=&jit=&cg=&bdtype=0&oriquery=&objurl=http%3A%2F%2Fpic1.win4000.com%2Fmobile%2F2018-11-16%2F5bee647bed97a.jpg&fromurl=ippr_z2C%24qAzdH3FAzdH3Fooo_z%26e3Botg9aaa_z%26e3Bv54AzdH3F45ktsj_1jpwts_8cdcdd_n_z%26e3Bip4s&gsm=3c&rpstart=0&rpnum=0&islist=&querylist=&nojc=undefined&dyTabStr=MCwzLDEsNiw0LDIsNSw3LDgsOQ%3D%3D", //你的封面地址
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      };
      return playerOptions;
    },
    swiperOption() {
      const swiperOption = {
        //左右前进后退按钮
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        notNextTick: true,
        pagination: {
          el: ".swiper-pagination",
        },
        loop: false,
        touchRatio: 2, // 触摸滑动的顺畅度
        initialSlide: 1, // 默认展示页
        autoplay: false, // 是否自动播放
        speed: 500, // 播放速度
        centeredSlides: false,
        paginationClickable: true,
        slidesPerView: 4,
        spaceBetween: 10,
      };
      return swiperOption;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .vjs-poster{
  background: rgb(242, 242, 242);
}
.content {
  .title {
    font-size: 32px;
    font-weight: bold;
    padding: 40px 0;
  }
  .top {
    font-size: 32px;
    font-weight: bold;
  }
  .btn {
    // margin-top: 40px;
    width: 310px;
    height: 70px;
    line-height: 70px;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    background: #000;
    border-radius: 50px;
  }
  p {
    margin: 0;
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 150px;
  .left {
    height: 100%;
    display: flex;
    align-items: center;
    width: 15%;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: space-around;
    min-width: 45%;
    & > p {
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .right {
    width: 20.5%;
    & > div {
      margin-top: 10px;
      display: flex;
      & > p {
        cursor: pointer;
        margin-right: 15px;
        width: 45px;
        height: 45px;
        border-radius: 100%;
        background: #aaa;
      }
    }
  }
}
::v-deep.header_1 {
  video {
    width: 100% !important;
    object-fit: fill; //消除留白
  }
  .video-player {
    box-shadow: none !important;
    width: 100% !important;
    height: 630px !important;
    .video-js {
      background: rgb(242, 242, 242) !important;
      height: 100%;
    }
    /*播放按钮设置成宽高一致，圆形，居中*/
    .vjs-big-play-button {
      border: 1px solid #000 !important;
      background-color: #000 !important;
      font-size: 3.5em;
      border-radius: 100% !important;
      height: 2em !important;
      width: 2em !important;
      line-height: 2em !important;
      margin-top: -1em !important;
      margin-left: -1em !important;
      outline: none;
    }
    /*鼠标进入播放器后，播放按钮颜色会变*/
    .video-js:hover .vjs-big-play-button,
    .vjs-custom-skin > .video-js .vjs-big-play-button:active,
    .vjs-custom-skin > .video-js .vjs-big-play-button:focus {
      background-color: rgba(10, 67, 237, 0.854) !important;
      border: 1px solid rgba(10, 67, 237, 0.854) !important;
    }
  }
}
.header_2 {
  padding-top: 70px;
  width: 100%;
  text-align: center;
  height: 615px;
  margin-bottom: 60px;

  .text {
    font-weight: 600;
    margin: 20px 20%;
    line-height: 25px;
  }
  .img_ {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .img_text {
    width: 250px;
    height: 250px;
    margin-right: 40px;
    & > img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
    & > p {
      margin-top: 20px;
      font-size: 28px;
      font-weight: 600;
    }
  }
}
.header_3 {
  width: 100%;
  height: 225px;
  background: rgb(242, 242, 242);
  text-align: center;
  line-height: 100px;
  padding-top: 15px;
}
::v-deep .center {
  height: 100%;
  .swiper-button-prev {
    left: 13% !important;
    background-image: url("@/assets/you.png");
    background-size: 32px;
    transform: rotateY(180deg);
    top: 135px;
  }
  .swiper-button-next {
    right: 13% !important;
    background-image: url("@/assets/you.png");
    background-size: 32px;
    top: 135px;
  }
  .swiper-container {
    margin: 0px 20rem;
    margin-top: 60px;
    position: initial !important;
  }
  .btn {
    width: 180px;
    height: 55px;
    line-height: 55px;
    margin-top: 20px;
    border-radius: 100px;
  }
}
::v-deep .videos-4 {
  text-align: center;
  video {
    width: 100% !important;
    object-fit: fill; //消除留白
  }
  .video-player {
    margin-right: 20px;
    margin-bottom: 10px;
    box-shadow: none !important;
    width: 590px !important;
    height: 330px !important;
    .video-js {
      background: rgb(242, 242, 242) !important;
      height: 330px;
      width: 590px;
    }
    /*播放按钮设置成宽高一致，圆形，居中*/
    .vjs-big-play-button {
      border: 1px solid #000 !important;
      background-color: #000 !important;
      font-size: 3.5em;
      border-radius: 100% !important;
      height: 2em !important;
      width: 2em !important;
      line-height: 2em !important;
      outline: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    /*鼠标进入播放器后，播放按钮颜色会变*/
    .video-js:hover .vjs-big-play-button,
    .vjs-custom-skin > .video-js .vjs-big-play-button:active,
    .vjs-custom-skin > .video-js .vjs-big-play-button:focus {
      background-color: rgba(10, 67, 237, 0.854) !important;
      border: 1px solid rgba(10, 67, 237, 0.854) !important;
    }
  }
}
::v-deep.swiper_2 {
  background: rgb(242, 242, 242);
  text-align: center;

  .swiper-button-next,
  .swiper-button-prev {
    background-image: url("@/assets/you-o.png") !important;
  }
}
::v-deep.suggest {
  text-align: center;
  padding: 30px 0;
  .el-form-item__label {
    font-size: 18px;
    font-weight: bold;
    color: #000;
  }
  .el-input__inner {
    background: rgb(242, 242, 242);
    border: none;
    border-radius: 8px;
    height: 45px;
  }
  .el-textarea__inner {
    background: rgb(242, 242, 242);
    border: none;
    border-radius: 8px;
    resize: none; //去掉右下角的拖动高的三角
  }
  .btn {
    width: 190px;
    height: 60px;
    font-size: 16px;
    line-height: 60px;
  }
}
.bottom {
  display: flex;
  justify-content: space-around;
  height: 380px;
  background: rgb(230, 230, 230);
  .left {
    width: 30%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    height: 55%;
    .text {
      font-size: 14px;
      font-weight: 600;
      line-height: 25px;
    }
  }
  .right {
    height: 50%;
    font-weight: 600;
    display: flex;
    justify-content: space-around;
    width: 25%;
    align-items: center;
  }
}
</style>
