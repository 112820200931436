var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"right"},[_c('p',{staticStyle:{"font-size":"14px","font-weight":"600","margin-left":"10px"}},[_vm._v("LINK TO SOCIAL NETWORKS")]),_c('div',{staticStyle:{"text-align":"center"}},_vm._l((5),function(item){return _c('p',{key:item})}),0)])]),_c('div',{staticClass:"header_1"},[_c('video-player',{ref:"videoPlayer",staticClass:"video-player vjs-custom-skin",attrs:{"playsinline":true,"options":_vm.playerOptions}})],1),_c('div',{staticClass:"header_2"},[_c('div',{staticClass:"top"},[_vm._v("Mission of Guanji")]),_c('div',{staticClass:"text"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ")]),_c('div',{staticClass:"img_"},_vm._l((4),function(item){return _c('div',{key:item,staticClass:"img_text"},[_c('img',{style:({
            width: _vm.imgWidth / 7.9 + 'px',
            height: _vm.imgWidth / 7.9 + 'px',
            borderRadius: '100%',
          }),attrs:{"src":require("@/assets/aa.jpg")}}),_c('p',[_vm._v("Crowdfunding for left-behind children")])])}),0)]),_c('div',{staticStyle:{"height":"50px"}}),_vm._m(2),_c('div',{staticClass:"center header_2"},[_c('div',{staticClass:"top"},[_vm._v("Our products")]),_c('div',{staticClass:"text"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ")]),_c('div',{staticStyle:{"position":"relative","padding-bottom":"30px"}},[_c('swiper',{attrs:{"options":_vm.swiperOption}},[_vm._l((6),function(item,index){return _c('swiper-slide',{key:index},[_c('img',{staticClass:"swiper-slide",attrs:{"src":require("@/assets/aa.jpg")}}),_c('div',{staticClass:"swiper-slide",staticStyle:{"text-align":"left"}},[_c('div',{staticStyle:{"font-size":"26px","font-weight":"bold","margin":"20px 0"}},[_vm._v(" Product "+_vm._s(item)+" ")]),_c('div',{staticStyle:{"font-weight":"bold"}},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting in-dustry. ")]),_c('button',{staticClass:"btn"},[_vm._v("BUY NOW")])])])}),_c('div',{staticClass:"swiper-button-next",style:({
            top: _vm.imgWidth / 7.9 / 2 + 'px',
          }),attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",style:({
            top: _vm.imgWidth / 7.9 / 2 + 'px',
          }),attrs:{"slot":"button-prev"},slot:"button-prev"})],2)],1)]),_vm._m(3),_c('div',{staticClass:"videos-4"},[_c('div',{staticClass:"title"},[_vm._v("Our territory, our tradition")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","padding-bottom":"70px"}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('video-player',{ref:"videoPlayer1",attrs:{"playsinline":true,"options":_vm.playerOptions}}),_c('div',{staticClass:"title",staticStyle:{"padding":"0"}},[_vm._v("Title of the video")])],1),_c('div',{staticStyle:{"text-align":"left"}},[_c('video-player',{ref:"videoPlayer1",attrs:{"playsinline":true,"options":_vm.playerOptions}}),_c('div',{staticClass:"title",staticStyle:{"padding":"0"}},[_vm._v("Title of the video")])],1)]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","padding-bottom":"70px"}},[_c('div',{staticStyle:{"text-align":"left"}},[_c('video-player',{ref:"videoPlayer1",attrs:{"playsinline":true,"options":_vm.playerOptions}}),_c('div',{staticClass:"title",staticStyle:{"padding":"0"}},[_vm._v("Title of the video")])],1),_c('div',{staticStyle:{"text-align":"left"}},[_c('video-player',{ref:"videoPlayer1",attrs:{"playsinline":true,"options":_vm.playerOptions}}),_c('div',{staticClass:"title",staticStyle:{"padding":"0"}},[_vm._v("Title of the video")])],1)])]),_c('div',{staticClass:"swiper_2 center"},[_c('div',{staticClass:"title"},[_vm._v("Our producers")]),_c('div',{staticStyle:{"position":"relative"},style:({ paddingBottom: _vm.imgWidth >= '1300' ? '30px' : 0 })},[_c('swiper',{staticStyle:{"margin-top":"0"},attrs:{"options":_vm.swiperOption}},[_vm._l((6),function(item,index){return _c('swiper-slide',{key:index},[_c('img',{style:({
              width: _vm.imgWidth / 7.9 + 'px',
              height: _vm.imgWidth / 7.9 + 'px',
              borderRadius: '100%',
            }),attrs:{"src":require("@/assets/aa.jpg")}}),_c('div',{staticClass:"swiper-slide",staticStyle:{"text-align":"center"}},[_c('div',{staticStyle:{"font-size":"26px","font-weight":"bold","margin":"20px 0"}},[_vm._v(" Product "+_vm._s(item)+" ")])])])}),_c('div',{staticClass:"swiper-button-next",style:({
            top: _vm.imgWidth / 7.9 / 2 + 'px',
          }),attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"swiper-button-prev",style:({
            top: _vm.imgWidth / 7.9 / 2 + 'px',
          }),attrs:{"slot":"button-prev"},slot:"button-prev"})],2)],1)]),_c('div',{staticClass:"header_2",staticStyle:{"margin-bottom":"0"}},[_c('div',{staticClass:"top"},[_vm._v("Certication")]),_c('div',{staticClass:"text"},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ")]),_c('div',{staticClass:"img_"},_vm._l((3),function(item){return _c('div',{key:item,staticClass:"img_text"},[_c('img',{staticStyle:{"border-radius":"0"},style:({
            width: _vm.imgWidth / 7.9 + 'px',
            height: _vm.imgWidth / 7.9 + 'px',
          }),attrs:{"src":require("@/assets/aa.jpg")}}),_c('p',[_vm._v("Certiicate "+_vm._s(item))])])}),0)]),_vm._m(4),_c('div',{staticClass:"suggest"},[_c('div',{staticClass:"title"},[_vm._v("Contact us")]),_c('div',{staticStyle:{"margin":"0px 23%","margin-bottom":"30px","font-weight":"bold"}},[_vm._v(" Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley ")]),_c('el-form',{staticStyle:{"margin":"0px 30%"},attrs:{"label-position":"top","label-width":"80px","model":_vm.form}},[_c('el-form-item',{staticStyle:{"text-align":"left"},attrs:{"label":"NAME"}},[_c('el-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('el-form-item',{staticStyle:{"text-align":"left"},attrs:{"label":"E-MAIL"}},[_c('el-input',{model:{value:(_vm.form.mail),callback:function ($$v) {_vm.$set(_vm.form, "mail", $$v)},expression:"form.mail"}})],1),_c('el-form-item',{staticStyle:{"text-align":"left"},attrs:{"label":"MESSAGE"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 6, maxRows: 9 }},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1),_c('div',{staticStyle:{"text-align":"left"}},[_c('button',{staticClass:"btn"},[_vm._v("SEND MESSAGE")])])],1)],1),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left"},[_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require("@/assets/logo.png")}}),_c('div',{staticClass:"left_1"},[_c('p',{staticStyle:{"font-size":"34px"}},[_vm._v("逛集网")]),_c('p',{staticStyle:{"color":"#c1c1c1","font-size":"18px"}},[_vm._v("guangji.wang")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center"},[_c('p',[_vm._v("Mission")]),_c('p',[_vm._v("Our product")]),_c('p',[_vm._v("Our territory")]),_c('p',[_vm._v("Our producers")]),_c('p',[_vm._v("Certication")]),_c('p',[_vm._v("Contact us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_3"},[_c('div',{staticClass:"top"},[_vm._v("Discover the Guanji products")]),_c('button',{staticClass:"btn"},[_vm._v("GO TO PURCHASE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_3"},[_c('div',{staticClass:"top"},[_vm._v("Show all guanji products")]),_c('button',{staticClass:"btn"},[_vm._v("GO TO PURCHASE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header_3"},[_c('div',{staticClass:"top"},[_vm._v("Show all guanji products")]),_c('button',{staticClass:"btn"},[_vm._v("GO TO PURCHASE")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom"},[_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":require("@/assets/guangji.png")}}),_c('div',{staticClass:"text"},[_c('p',[_vm._v("CORPORATE DATA")]),_c('p',[_vm._v("地址：北京市朝阳区CBD 商务区")]),_c('p',[_vm._v("电话：15822980377")]),_c('p',[_vm._v("邮箱：1175053853@qq.com")])])]),_c('div',{staticClass:"right"},[_c('p',[_vm._v("Link 1")]),_c('p',[_vm._v("Link 2")]),_c('p',[_vm._v("Link 3")]),_c('p',[_vm._v("Link 4")])])])
}]

export { render, staticRenderFns }